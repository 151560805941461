import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import BgColor from "../../layouts/BgColor"
import DatoStructuredTextBlock from "../DatoStructuredTextBlock"
import * as styles from "./DatoTextBlock.module.scss"

const DatoTextBlock = (props: Queries.DatoTextBlockFragment) => {
  // console.debug("Rendering DatoTextBlock", props)

  const { textAlign = "Left" } = props

  // TODO sisältää tyylejä vaikka on Dato blokki => UI ja data sekaisin joka estää migraation Gatsbystä

  return (
    <BgColor
      color={props.textBackground ?? undefined}
      id={slugify(props.title)}
      className={`${styles.container} ${styles[`align${textAlign}`]}`}>
      <div className="layout-container">
        {props.title && <h2 className={styles.title}>{props.title}</h2>}
        {props.content && (
          <div className={styles.textContent}>
            <DatoStructuredTextBlock data={props.content} shiftHeadings={1} />
          </div>
        )}
      </div>
    </BgColor>
  )
}

export default DatoTextBlock

export const query = graphql`
  fragment DatoTextBlock on DatoCmsTextBlock {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    layout
    textAlign
    textBackground
    content {
      value
      links {
        __typename
        ... on DatoCmsRouteLink {
          ...DatoRouteLink
        }
        ... on DatoCmsExternalUrl {
          ...DatoExternalUrl
        }
        ... on DatoCmsArticleLink {
          ...DatoArticleLink
        }
        ... on DatoCmsProductLink {
          ...DatoProductLink
        }
        ... on DatoCmsProductFinderLink {
          ...DatoProductFinderLink
        }
      }
    }
  }
`
